import InformationPreviewGrid from "../components/information-preview-grid";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import React from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import { toPlainText } from "../lib/helpers";
import { responsiveTitle1 } from "../components/typography.module.css";

export const query = graphql`
  query ArchiveInformationPageQuery {
    posts: allSanityInformation(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) { 
      edges {
        node {
          id
          _id
          publishedAt
          showConfetti
          enableComments
          enableGatedContent
          gateStyle
          
          emailSignupWidgetTitle
          emailSignupWidgetImage {
                ...SanityImage
                alt
          }
          emailSignupWidgetParagraphText
          textNextToKey
          emailSignupWidgetButtonText
          emailSignupWidgetTextboxPlaceholder
          brazeCustomAttributes {
            customAttributeKey
            customAttributeValue
          }
          _rawGatedContentBody(resolveReferences: { maxDepth: 5 })
          _rawGatedContentReadMoreText(resolveReferences: { maxDepth: 5 })
          hideTitle
          mainImage {
            ...SanityImage
            alt
          }
          title
          slug {
            current
          }
          subfolder {
            folder
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          widgets {
            widgetType {
              title
            }
            title
            adZone
            mobileAdZone
            _rawBody
            _rawMainImage
            id
            mainImage {
              ...SanityImage
              alt
            }
            linkReference {
              ... on SanityPost {
                _type
                slug {
                  current
                }
              }
              ... on SanityQuestionPost {
                _type
                slug {
                  current
                }
              }
              ... on SanityInformation {
                _type
                slug {
                  current
                }
                subfolder {
                  folder
                }
              }
              ... on SanityLinkCategory {
                url
                _type
              }
            }
          }
        }
      }
    }
  }
`;

const ArchivePage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);

  return (
    <Layout>
      <SEO
        title={postNodes.title || "Information"}
        description={toPlainText(postNodes._rawExcerpt)}
      />
      <Container>
        <h1 className={responsiveTitle1}>Info</h1>
        {postNodes && postNodes.length > 0 && (
          <InformationPreviewGrid nodes={postNodes} />
        )}
      </Container>
    </Layout>
  );
};

export default ArchivePage;
